import Uy365 from '../assets/img/365-uy@2x.png';
import Cabal from '../assets/img/CABAL@2x.png';
import Creditel from '../assets/img/CREDITEL@2x.png';
import Hering from '../assets/img/Hering@2x.png';
import LaBanca from '../assets/img/LaBanca@2x.png';
import PedidosYa from '../assets/img/PedidosYa@2x.png';
import Acher from '../assets/img/acher@2x.png';
import Aebu from '../assets/img/aebu@2x.png';
import Agnes from '../assets/img/agnes@2x.png';
import AlienStore from '../assets/img/alienstore@2x.png';
import BarracaEuropa from '../assets/img/barraca-europa@2x.png';
import Bas from '../assets/img/bas@2x.png';
import Blanqueria from '../assets/img/blanqueria@2x.png';
import Capra from '../assets/img/capra@2x.png';
import Carrera from '../assets/img/carrera@2x.png';
import Champagne from '../assets/img/champagne.png';
import Clon from '../assets/img/clon@2x.png';
import Cot from '../assets/img/cot@2x.png';
import CoverCompany from '../assets/img/cover-company.png';
import Dac from '../assets/img/dac@2x.png';
import DanielCassin from '../assets/img/daniel-cassin@2x.png';
import DanielHogar from '../assets/img/daniel-hogar@2x.png';
import Divino from '../assets/img/divino@2x.png';
import ElectroVentas from '../assets/img/electroventas@2x.png';
import EmporioHogar from '../assets/img/emporio-hogar@2x.png';
import Ene from '../assets/img/ene-e-commerce.png';
import Epicentro from '../assets/img/epicentro.png';
import FarmaGlobal from '../assets/img/farmaglobal@2x.png';
import FenicioClient from '../assets/img/fenicio@2x.png';
import Fiserv from '../assets/img/fiserv@2x.png';
import Flacso from '../assets/img/flacso@2x.png';
import Forum from '../assets/img/forum@2x.png';
import Ganesha from '../assets/img/ganesha@2x.png';
import GrupoPiero from '../assets/img/grupo-piero2x.png';
import Guapa from '../assets/img/guapa@2x.png';
import Harrington from '../assets/img/harrington@2x.png';
import Hortensia from '../assets/img/hortensia@2x.png';
import Hts from '../assets/img/hts@2x.png';
import Hyundai from '../assets/img/hyundai@2x.png';
import Ingeniarte from '../assets/img/ingeniarte@2x.png';
import LaIsla from '../assets/img/la-isla@2x.png';
import Legacy from '../assets/img/legacy@2x.png';
import Life from '../assets/img/life@2x.png';
import Linconlns from "../assets/img/linconln's@2x.png";
import Abitab from '../assets/img/logo-abitab@2x.png';
import AgileCommerce from '../assets/img/logo-agilecommerce@2x.png';
import Amex from '../assets/img/logo-amex@2x.png';
import Anda from '../assets/img/logo-anda@2x.png';
import Bbva from '../assets/img/logo-bbva@2x.png';
import Brou from '../assets/img/logo-brou@2x.png';
import CabalLogo from '../assets/img/logo-cabal@2x.png';
import CreditelLogo from '../assets/img/logo-creditel@2x.png';
import Edenred from '../assets/img/logo-edenred@2x.png';
import FenicioSm from '../assets/img/logo-fenicio-sm@2x.png';
import Itau from '../assets/img/logo-itau@2x.png';
import Luna from '../assets/img/logo-luna@2x.png';
import Magento from '../assets/img/logo-magento@2x.png';
import Master from '../assets/img/logo-master@2x.png';
import NopCommerce from '../assets/img/logo-nopcommerce@2x.png';
import Oca from '../assets/img/logo-oca@2x.png';
import PagoDespues from '../assets/img/logo-pagodespues@2x.png';
import Pass from '../assets/img/logo-pass@2x.png';
import Pix from '../assets/img/logo-pix@2x.png';
import Prestashop from '../assets/img/logo-prestashop@2x.png';
import Prex from '../assets/img/logo-prex@2x.png';
import RedPagos from '../assets/img/logo-redpagos@2x.png';
import Santander from '../assets/img/logo-santander@2x.png';
import Scotia from '../assets/img/logo-scotia@2x.png';
import Shopify from '../assets/img/logo-shoify@2x.png';
import Sistarbank from '../assets/img/logo-sistarbank@2x.png';
import Sodexo from '../assets/img/logo-sodexo@2x.png';
import SublimeSolutions from '../assets/img/logo-sublimesol@2x.png';
import Visa from '../assets/img/logo-visa@2x.png';
import Vtex from '../assets/img/logo-vtex@2x.png';
import Woo from '../assets/img/logo-woo@2x.png';
import Lolita from '../assets/img/lolita@2x.png';
import Menpi from '../assets/img/menpi@2x.png';
import Midinero from '../assets/img/midinero@2x.png';
import Mikangaroo from '../assets/img/mikangaroo@2x.png';
import MisPetates from '../assets/img/mispetates@2x.png';
import MissCarol from '../assets/img/misscarol@2x.png';
import MlCenter from '../assets/img/mlcenter@2x.png';
import MultiAhorroHogar from '../assets/img/mltiahorro-hogar@2x.png';
import Nnet from '../assets/img/nnet@2x.png';
import Nyr from '../assets/img/nyr@2x.png';
import Panthai from '../assets/img/panthai@2x.png';
import Pappolino from '../assets/img/pappolino@2x.png';
import Petra from '../assets/img/petra@2x.png';
import PieceofCake from '../assets/img/pieceofcake@2x.png';
import Pimenton from '../assets/img/pimenton@2x.png';
import Rasa from '../assets/img/rasa@2x.png';
import RedPagosClient from '../assets/img/redpagos@2x.png';
import ReyEntretenimiento from '../assets/img/rey-entretenimineto@2x.png';
import Rotunda from '../assets/img/rotunda@2x.png';
import Route66 from '../assets/img/route66@2x.png';
import Rusty from '../assets/img/rusty@2x.png';
import SabremosTomar from '../assets/img/sabremos-tomar@2x.png';
import SanJose from '../assets/img/san-jose@2x.png';
import Saura from '../assets/img/saura@2x.png';
import Sensi from '../assets/img/sensi@2x.png';
import SodexoClient from '../assets/img/sodexo@2x.png';
import SportMarket from '../assets/img/sportmarket@2x.png';
import Stadium from '../assets/img/stadium@2x.png';
import StiendaUy from '../assets/img/stienda.uy@2x.png';
import Symphorine from '../assets/img/symphorine@2x.png';
import Tascani from '../assets/img/tascani@2x.png';
import Tcc from '../assets/img/tcc@2x.png';
import TecnoWeb from '../assets/img/tecno-web@2x.png';
import Thermomix from '../assets/img/thermomix@2x.png';
import Todomusica from '../assets/img/todomusica@2x.png';
import TopTecnoUy from '../assets/img/toptecno.uy@2x.png';
import Uniform from '../assets/img/uniform@2x.png';
import UniversoBinario from '../assets/img/universo-binario@2x.png';
import Voyentaxi from '../assets/img/voyentaxi@2x.png';
import ZonaTecno from '../assets/img/zona-tecno@2x.png';
import { Logo } from '../types/logos.types';

interface Logos {
  [key: string]: Logo;
}

const logos: Logos = Object.freeze({
  abitab: { img: Abitab, title: 'Abitab' },
  acher: { img: Acher, title: 'Acher' },
  aebu: { img: Aebu, title: 'Aebu' },
  agileCommerce: { img: AgileCommerce, title: 'Agile Commerce' },
  agnes: { img: Agnes, title: 'Agnes' },
  alienStore: { img: AlienStore, title: 'Alien Store' },
  americanExpress: { img: Amex, title: 'Amex' },
  anda: { img: Anda, title: 'Anda' },
  barracaEuropa: { img: BarracaEuropa, title: 'Barraca Europa' },
  bas: { img: Bas, title: 'Bas' },
  bbva: { img: Bbva, title: 'BBVA' },
  blanqueria: { img: Blanqueria, title: 'Blanqueria' },
  brou: { img: Brou, title: 'Brou' },
  cabal: { img: Cabal, title: 'Cabal' },
  capra: { img: Capra, title: 'Capra' },
  carrera: { img: Carrera, title: 'Carrera' },
  clon: { img: Clon, title: 'Clon' },
  creditel: { img: Creditel, title: 'Creditel' },
  dac: { img: Dac, title: 'Dac' },
  danielCassin: { img: DanielCassin, title: 'Daniel Cassin' },
  danielHogar: { img: DanielHogar, title: 'Daniel Hogar' },
  divino: { img: Divino, title: 'Divino' },
  edenRed: { img: Edenred, title: 'EdenRed' },
  electroVentas: { img: ElectroVentas, title: 'Electro Ventas' },
  emporioHogar: { img: EmporioHogar, title: 'Emporio Hogar' },
  farmaGlobal: { img: FarmaGlobal, title: 'FarmaGlobal' },
  fenicio: { img: FenicioSm, title: 'Fenicio' },
  fenicioClient: { img: FenicioClient, title: 'Fenicio' },
  fiserv: { img: Fiserv, title: 'Fiserv' },
  flacso: { img: Flacso, title: 'Flacso' },
  forum: { img: Forum, title: 'Forum' },
  ganesha: { img: Ganesha, title: 'Ganesha' },
  guapa: { img: Guapa, title: 'Guapa' },
  harrington: { img: Harrington, title: 'Harrington' },
  hering: { img: Hering, title: 'Hering' },
  hortensia: { img: Hortensia, title: 'Hortensia' },
  hts: { img: Hts, title: 'Hts' },
  hyundai: { img: Hyundai, title: 'Hyundai' },
  itau: { img: Itau, title: 'Itau' },
  laBanca: { img: LaBanca, title: 'La Banca' },
  laIsla: { img: LaIsla, title: 'La Isla' },
  life: { img: Life, title: 'Life' },
  legacy: { img: Legacy, title: 'Legacy' },
  lincolns: { img: Linconlns, title: 'Linconlns' },
  lolita: { img: Lolita, title: 'Lolita' },
  magento: { img: Magento, title: 'Magento' },
  master: { img: Master, title: 'Master' },
  menpi: { img: Menpi, title: 'Menpi' },
  miDinero: { img: Midinero, title: 'Midinero' },
  mikangaroo: { img: Mikangaroo, title: 'Mikangaroo' },
  misPetates: { img: MisPetates, title: 'Mis Petates' },
  missCarol: { img: MissCarol, title: 'MissCarol' },
  mlCenter: { img: MlCenter, title: 'MlCenter' },
  multiAhorroHogar: { img: MultiAhorroHogar, title: 'Multi Ahorro Hogar' },
  nnet: { img: Nnet, title: 'Nnet' },
  nopCommerce: { img: NopCommerce, title: 'NopCommerce' },
  nyr: { img: Nyr, title: 'Nyr' },
  oca: { img: Oca, title: 'Oca' },
  pagoDespues: { img: PagoDespues, title: 'PagoDespues' },
  panthai: { img: Panthai, title: 'Panthai' },
  pappolino: { img: Pappolino, title: 'Pappolino' },
  pass: { img: Pass, title: 'Pass' },
  pedidosYa: { img: PedidosYa, title: 'PedidosYa' },
  petra: { img: Petra, title: 'Petra' },
  pieceofCake: { img: PieceofCake, title: 'Pieceof Cake' },
  pimenton: { img: Pimenton, title: 'Pimenton' },
  prestashop: { img: Prestashop, title: 'Prestashop' },
  prex: { img: Prex, title: 'Prex' },
  rasa: { img: Rasa, title: 'Rasa' },
  redPagosClient: { img: RedPagosClient, title: 'Redpagos' },
  redPagos: { img: RedPagos, title: 'Redpagos' },
  pix: { img: Pix, title: 'Pix' },
  rotunda: { img: Rotunda, title: 'Rotunda' },
  route66: { img: Route66, title: 'Route66' },
  rusty: { img: Rusty, title: 'Rusty' },
  sabremosTomar: { img: SabremosTomar, title: 'Sabremos Tomar' },
  sanJose: { img: SanJose, title: 'San Jose' },
  santander: { img: Santander, title: 'Santander' },
  saura: { img: Saura, title: 'Saura' },
  scotia: { img: Scotia, title: 'Scotia' },
  sensi: { img: Sensi, title: 'Sensi' },
  shopify: { img: Shopify, title: 'Shopify' },
  luna: { img: Luna, title: 'Luna' },
  sistarBank: { img: Sistarbank, title: 'Sistarbank' },
  sodexoClient: { img: SodexoClient, title: 'Sodexo' },
  sodexo: { img: Sodexo, title: 'Sodexo' },
  sportMarket: { img: SportMarket, title: 'SportMarket' },
  stadium: { img: Stadium, title: 'Stadium' },
  stiendaUy: { img: StiendaUy, title: 'Stienda Uy' },
  sublimeSolutions: { img: SublimeSolutions, title: 'Sublime Solutions' },
  symphorine: { img: Symphorine, title: 'Symphorine' },
  tcc: { img: Tcc, title: 'Tcc' },
  tecnoWeb: { img: TecnoWeb, title: 'Tecno Web' },
  tascani: { img: Tascani, title: 'Tascani' },
  thermomix: { img: Thermomix, title: 'Thermomix' },
  todomusica: { img: Todomusica, title: 'Todomusica' },
  topTecnoUy: { img: TopTecnoUy, title: 'Top Tecno Uy' },
  uniform: { img: Uniform, title: 'Uniform' },
  universoBinario: { img: UniversoBinario, title: 'Universo Binario' },
  uy365: { img: Uy365, title: 'Uy365' },
  visa: { img: Visa, title: 'Visa' },
  voyentaxi: { img: Voyentaxi, title: 'Voyentaxi' },
  vtex: { img: Vtex, title: 'Vtex' },
  wooCommerce: { img: Woo, title: 'Woo Commerce' },
  zonaTecno: { img: ZonaTecno, title: 'ZonaTecno' },
  cot: { img: Cot, title: 'Cot' },
  coverCompany: { img: CoverCompany, title: 'Cover Company' },
  champagne: { img: Champagne, title: 'Champagne Home & Deco' },
  epicentro: { img: Epicentro, title: 'Epicentro' },
  grupoPiero: { img: GrupoPiero, title: 'GrupoPiero' },
  reyEntretenimiento: { img: ReyEntretenimiento, title: 'El Rey del Entretenimiento' },
  ingeniarte: { img: Ingeniarte, title: 'Ingeniarte' },
  ene: { img: Ene, title: 'Ene E-commerce' },
});

export const paymentMethodsLogos: Logo[] = [
  logos.visa,
  logos.master,
  logos.oca,
  logos.sistarBank,
  logos.wooCommerce,
  logos.magento,
  logos.fenicio,
  logos.vtex,
  logos.prestashop,
  logos.shopify,
  logos.luna,
];

export const clientLogos: Logo[] = [
  logos.pedidosYa,
  logos.divino,
  logos.laBanca,
  logos.dac,
  logos.fenicioClient,
  logos.uy365,
  logos.acher,
  logos.aebu,
  logos.agnes,
  logos.alienStore,
  logos.barracaEuropa,
  logos.ganesha,
  logos.cabal,
  logos.capra,
  logos.carrera,
  logos.creditel,
  logos.flacso,
  logos.danielCassin,
  logos.danielHogar,
  logos.electroVentas,
  logos.emporioHogar,
  logos.sanJose,
  logos.guapa,
  logos.farmaGlobal,
  logos.forum,
  logos.hering,
  logos.hortensia,
  logos.laIsla,
  logos.life,
  logos.legacy,
  logos.bas,
  logos.clon,
  logos.fiserv,
  logos.harrington,
  logos.hts,
  logos.lincolns,
  logos.rotunda,
  logos.route66,
  logos.hyundai,
  logos.blanqueria,
  logos.menpi,
  logos.sportMarket,
  logos.lolita,
  logos.mikangaroo,
  logos.miDinero,
  logos.misPetates,
  logos.missCarol,
  logos.mlCenter,
  logos.nnet,
  logos.multiAhorroHogar,
  logos.nyr,
  logos.panthai,
  logos.pappolino,
  logos.petra,
  logos.pieceofCake,
  logos.pimenton,
  logos.rasa,
  logos.redPagos,
  logos.rusty,
  logos.sabremosTomar,
  logos.saura,
  logos.sensi,
  logos.sodexoClient,
  logos.stadium,
  logos.stiendaUy,
  logos.symphorine,
  logos.tcc,
  logos.tecnoWeb,
  logos.tascani,
  logos.thermomix,
  logos.todomusica,
  logos.uniform,
  logos.topTecnoUy,
  logos.universoBinario,
  logos.voyentaxi,
  logos.zonaTecno,
  logos.cot,
  logos.coverCompany,
  logos.champagne,
  logos.epicentro,
  logos.grupoPiero,
  logos.reyEntretenimiento,
  logos.ingeniarte,
];

export const gatewayViewPaymentMethods: Logo[] = [
  logos.visa,
  logos.master,
  logos.oca,
  logos.sistarBank,
  { ...logos.cabal, img: CabalLogo },
  logos.pass,
  { ...logos.creditel, img: CreditelLogo },
  logos.sodexo,
  logos.americanExpress,
  logos.prex,
  logos.edenRed,
  logos.anda,
  logos.brou,
  logos.santander,
  logos.itau,
  logos.scotia,
  logos.bbva,
  logos.pagoDespues,
  logos.abitab,
  logos.redPagos,
  logos.pix,
];

export const gatewayViewIntegrations: Logo[] = [
  logos.wooCommerce,
  logos.magento,
  logos.prestashop,
  logos.fenicio,
  logos.vtex,
  logos.nopCommerce,
  logos.agileCommerce,
  logos.sublimeSolutions,
  logos.shopify,
  logos.luna,
  logos.ene,
];
